import React, { useState, useRef, lazy, Suspense } from "react";
import "./HeroVideo.scss";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import useIsMobile from "../../hooks/useIsMobile";
import { Oval } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const HeroVideo = ({ padTop }) => {
  const [isActive, setIsActive] = useState(false);
  const videoRef = useRef(null);

  const { t } = useTranslation();

  const toggleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setIsActive(!isActive);
  };

  const isMobile = useIsMobile();

  const attributes = {
    autoPlay: true,
    muted: true,
    loop: true,
    controls: false,
    ref: videoRef,
    preload: "auto",
  };

  const volIconAttr = {
    htmlColor: "#ffffff",
    fontSize: "large",
  };

  let deviceVideoSrc = isMobile
    ? "videos/sarani-mobile.mp4#t=0.001"
    : "videos/sarani-web.mp4#t=0.001";

  if (isMobile) {
    attributes.autoPlay = false;
    attributes.muted = false;
    attributes.controls = true;
    attributes.loop = false;
  }

  const LazyVideo = lazy(() => import("./LazyVideo"));

  const heroClasses = padTop ? `hero-video ${padTop}` : "hero-video";
  return (
    <div className={heroClasses}>
      <h1>{t("greetings.0")}</h1>
      <div className="description">{t("greetings.1")}</div>
      <div style={{ position: "relative" }}>
        <Suspense
          fallback={
            <div className="video-loader">
              <Oval
                height={80}
                width={80}
                color="#111111"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#111111"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          }
        >
          <LazyVideo {...attributes} deviceVideoSrc={deviceVideoSrc} />
        </Suspense>
        {isMobile ? null : (
          <span className="toggleSound" onClick={toggleMute}>
            {isActive ? (
              <VolumeUpIcon {...volIconAttr} />
            ) : (
              <VolumeOffIcon {...volIconAttr} />
            )}
          </span>
        )}
      </div>
    </div>
  );
};

export default HeroVideo;
