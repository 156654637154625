import React, { lazy, Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";
import Footer from "../Footer/Footer";
import Projects from "../Projects/Projects";
import baseURL from "../../url/baseUrl";
import checkLinkInData from "../../utils/checkLinkInData";
import { Oval } from "react-loader-spinner";

import "./ProjectDetails.scss";
import i18next from "i18next";
import NavbarWrapper from "../Navbar/Navbar";
import LinkForm from "../LinkForm/LinkForm";

const ProjectDetails = ({ data }) => {
  const { id } = useParams();
  const isMobile = useIsMobile();
  const [language, setLanguage] = useState(i18next.language);
  const [translatedData, setTranslatedData] = useState([]);

  const project = checkLinkInData(data, id, [
    "Project_title",
    "Project_description",
  ])[0].attributes;

  const heroImg = isMobile
    ? project?.Mobile_hero_image?.data?.attributes?.url
    : project?.Hero_image?.data?.attributes?.url;

  const imgUrl = heroImg ? `${baseURL}${heroImg}` : "";

  const pageImages = project?.Project_page_images?.data;

  const sortedImages =
    pageImages?.sort((a, b) =>
      a.attributes.name.localeCompare(b.attributes.name)
    ) || [];

  const getImageClassName = (image) => {
    const { width, height } = image.attributes;
    const aspectRatio = width / height;
    if (aspectRatio <= 1.1 && aspectRatio > 0.9) {
      return "square-img";
    } else if (width < 1510 && width > 756) {
      return "padding-img";
    } else {
      return "rect-img";
    }
  };

  const videosUrls = project.Project_video.data
    ? project.Project_video.data.map((obj) => obj.attributes.url)
    : [];
  const LazyVideo = lazy(() => import("./Video"));

  const videos = videosUrls.map((video, idx) => {
    return (
      <Suspense
        fallback={
          <div className="video-loader">
            <Oval
              height={80}
              width={80}
              color="#111111"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#111111"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        }
      >
        <LazyVideo
          src={`https://back.sarani.studio${video}#t=0.001`}
          key={idx}
        />
      </Suspense>
    );
  });

  useEffect(() => {
    const getTranslatedData = (project) => {
      switch (language) {
        case "ar":
          return [
            project.AR_Project_title || project.Project_title,
            project.AR_Project_description || project.Project_description,
            project.AR_Title || project.Title,
            project.AR_Text || project.Text,
          ];
        case "fr":
        case "fr-FR":
          return [
            project.Project_title,
            project.FR_Project_description || project.Project_description,
            project.FR_Title || project.Title,
            project.FR_Text || project.Text,
          ];
        case "en":
        case "en-US":
          return [
            project.Project_title,
            project.Project_description,
            project.Title,
            project.Text,
          ];
        default:
          return [
            project.Project_title,
            project.Project_description,
            project.Title,
            project.Text,
          ];
      }
    };

    const translatedData = getTranslatedData(project);
    setTranslatedData(translatedData);
  }, [project, language]);

  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguage(i18next.language);
    };

    i18next.on("languageChanged", handleLanguageChange);

    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, []);

  return (
    <>
      <NavbarWrapper />
      <div className="project-details">
        <div className="project-details-container">
          <div className="project-banner">
            <img src={imgUrl} alt="hero-img" />
            <div className="project-banner-overlay" />
            <div className="project-banner-title">
              <h1>{translatedData[0]}</h1>
              <h3>{translatedData[1]}</h3>
            </div>
          </div>
          <div className="project-title">
            <div>{translatedData[2]}</div>
          </div>
          <div className="divided-text">
            <p>{translatedData[3]}</p>
          </div>
          <div className="project-videos">{videos}</div>
          <div className="project-gallery">
            {sortedImages.map((image, index) => (
              <img
                key={index}
                src={`${baseURL}${image.attributes.url}`}
                alt="project-img"
                className={getImageClassName(image)}
              />
            ))}
          </div>
        </div>
      </div>
      <Projects data={data} />
      <LinkForm />
      <Footer />
    </>
  );
};

export default ProjectDetails;
