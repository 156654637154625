import React from "react";
import Form from "../Form/Form";
import happiness from "../../assets/images/happiness.svg";
import { useTranslation } from "react-i18next";
import "./LinkForm.scss";

const LinkForm = () => {
  const { t } = useTranslation();
  return (
    <div className="linkform">
      <div className="linkform-container">
        <div className="linkform-title">
          {t("link_form.0")}
          <a
            href="https://1d4o4gdt2w1.typeform.com/to/mIpgxGDj"
            target="_blank"
            rel="noreferrer"
            className="linkform-btn"
          >
            {t("contact_home.1")}
          </a>
        </div>
        <div className="linkform-content">
          <img className="happiness" src={happiness} alt="" />
          <h6>{t("link_form.1")}</h6>
          <div>{t("link_form.2")}</div>
        </div>
      </div>
    </div>
  );
};

export default LinkForm;
