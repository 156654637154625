import React from "react";
import aircorsica from "../../assets/images/brands/aircorsica.svg";
import bmw from "../../assets/images/brands/bmw.svg";
import bytedance from "../../assets/images/brands/bytedance.svg";
import lamaison from "../../assets/images/brands/lamaison.svg";
import pico from "../../assets/images/brands/pico.svg";
import sony from "../../assets/images/brands/sony.svg";
import tiktok from "../../assets/images/brands/tiktok.svg";
import tradedoubler from "../../assets/images/brands/tradedoubler.svg";
import geodis from "../../assets/images/brands/geodis.svg";
import curie from "../../assets/images/brands/curie.png";
import voila from "../../assets/images/brands/voila.svg";
import "./BrandBanner.scss";

const BrandBanner = () => {
  const images = [
    sony,
    tiktok,
    tradedoubler,
    pico,
    bmw,
    aircorsica,
    voila,
    geodis,
    curie,
    lamaison,
    bytedance,
  ];

  return (
    <div className="ticker">
      <div className="ticker__list">
        {images.map((item, id) => (
          <div key={id} className="ticker__item">
            <img src={item} alt="brand" />
          </div>
        ))}
      </div>
      <div className="ticker__list">
        {images.map((item, id) => (
          <div key={id} className="ticker__item">
            <img src={item} alt="brand" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandBanner;
