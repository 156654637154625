import { SwiperSlide, Swiper } from "swiper/react";
import { Pagination } from "swiper";
import { Link } from "react-router-dom";
import baseURL from "../../url/baseUrl";
import useIsMobile from "../../hooks/useIsMobile";
import createSlugUrl from "../../utils/createSlugUrl";
import { useTranslation } from "react-i18next";
import React from "react";

import HoverArrow from "../../assets/images/carousel/hover-arrow.svg";
import HoverBlackArrow from "../../assets/images/carousel/hover-black-arrow.svg";
import "./Projects.scss";
import "swiper/css";
import "swiper/css/pagination";
import i18next from "i18next";

const Projects = ({ data, borderClass }) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const slicedData = data.slice(0, 5);

  const items = slicedData.map((item) => {
    const link = createSlugUrl(item, "Project_title", "Project_description");
    let carouselImg = item.attributes.Carousel_image.data.attributes.url;
    const imgUrl = carouselImg.length > 0 ? `${baseURL}${carouselImg}` : "";

    const getProjectData = (item) => {
      const language = i18next.language;

      switch (language) {
        case "ar":
          return [
            item.attributes.AR_Project_title || item.attributes.Project_title,
            item.attributes.AR_Project_description ||
              item.attributes.Project_description,
          ];
        case "fr":
        case "fr-FR":
          return [
            item.attributes.Project_title,
            item.attributes.FR_Project_description ||
              item.attributes.Project_description,
          ];
        case "en":
        case "en-US":
        default:
          return [
            item.attributes.Project_title,
            item.attributes.Project_description,
          ];
      }
    };
    const translatedData = getProjectData(item);

    return (
      <SwiperSlide key={item.id}>
        <Link to={`/projects/${link}`}>
          <div className="projects-card">
            <img className="projects-img" src={imgUrl} alt="" />
            <div className="projects-gradient">
              <h3>{translatedData[0]}</h3>
              <h4>{translatedData[1]}</h4>
              <img src={HoverArrow} alt="arrow" />
            </div>
          </div>
        </Link>
      </SwiperSlide>
    );
  });

  const viewAllSlide = (
    <SwiperSlide key="0">
      <Link to="/projects">
        <div className="projects-all-card">
          <div>
            <h4>{t("view_all.0")}</h4>
            <h4>{t("view_all.1")}</h4>
            <img src={HoverBlackArrow} alt="arrow" />
          </div>
        </div>
      </Link>
    </SwiperSlide>
  );

  const mobileItems = items.slice(0, items.length);

  const desktopSwiper = (
    <Swiper
      spaceBetween={30}
      loop={false}
      modules={[Pagination]}
      slidesPerView={3}
      pagination={{ type: "progressbar", clickable: true }}
    >
      {items.concat(viewAllSlide)}
    </Swiper>
  );

  const content =
    items.length > 0 ? (
      isMobile ? (
        <>
          <div className="projects-grid">{mobileItems}</div>
          <Link to="/projects">
            <div className="projects-button">
              {t("view_all.0")}
              {t("view_all.1")}
            </div>
          </Link>
        </>
      ) : (
        <div className="projects-swiper">{desktopSwiper}</div>
      )
    ) : (
      <div className="no-projects">No projects yet...</div>
    );

  const projectClass = borderClass ? `projects ${borderClass}` : "projects";

  return (
    <div className={projectClass}>
      <div className="projects-container">
        <h1>{t("projects_title.0")}</h1>
        {content}
      </div>
    </div>
  );
};

export default Projects;
