// Testimonials.js
import React, { useEffect, useRef } from "react";
import { TESTIMONIALS_COMPANIES } from "../../constants/arrays";
import "./Testimonials.scss";
import { useTranslation } from "react-i18next";
import "swiper/css/navigation";
import useIsMobile from "../../hooks/useIsMobile";
import TestimonialRenderer from "./TestimonialRenderer"; // Import the new component

const Testimonials = () => {
  const { t } = useTranslation();

  const breakpoints = {
    576: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 1,
    },
    920: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 3,
    },
  };

  const isMobile = useIsMobile();
  const swiperRef = useRef(null);
  useEffect(() => {
    if (isMobile) {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.destroy(true, true);
      }
    }
  }, [isMobile]);

  return (
    <div className="testi">
      <div className="testi-container">
        <h1>{t("testimonials_title.0")}</h1>
        <TestimonialRenderer
          testimonials={t("testimonials_cards", { returnObjects: true })}
          isMobile={isMobile}
          breakpoints={breakpoints}
        />
        <div className="testi-companies">
          {TESTIMONIALS_COMPANIES.map((company, id) => (
            <img
              key={id}
              src={require(`../../assets/images/testimonials/${company}`)}
              alt="company"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
