import React from "react";
import "./Home.scss";
import arrow from "../../assets/images/arrow.svg";
import MovingArrow from "../MovingArrow/MovingArrow";
import useIsMobile from "../../hooks/useIsMobile";
import UnlimitedCreativity from "../../assets/images/unlimited-creativity.svg";
import Fr_UnlimitedCreativity from "../../assets/images/fr_unlimited-creativity.svg";
import { useTranslation } from "react-i18next";
import BrandBanner from "../BrandBanner/BrandBanner";
import { Link } from "react-router-dom";
import i18next from "i18next";

const Home = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const lang = i18next.language;

  const unlimitedLogo =
    lang.toLowerCase() === "fr" ? Fr_UnlimitedCreativity : UnlimitedCreativity;

  return (
    <div className="home">
      <div className="home-container">
        <img className="home-logo" src={unlimitedLogo} alt="" />
        <div className="solutions">
          {Object.entries(t("solutions", { returnObjects: true })).map(
            ([key]) => (
              <span key={key}>{t(`solutions.${key}`)}</span>
            )
          )}
        </div>
        <div className="buttons">
          <a
            href="https://1d4o4gdt2w1.typeform.com/to/mIpgxGDj"
            target="_blank"
            rel="noreferrer"
            className="ylw-btn"
          >
            {t("button.0")}
          </a>

          <Link to="/projects">
            <div className="ylw-border-btn">{t("button.1")}</div>
          </Link>
        </div>
        <BrandBanner />
        {isMobile ? <img className="arrow" src={arrow} alt="" /> : <MovingArrow />}
      </div>
    </div>
  );
};

export default Home;
