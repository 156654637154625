const createSlugUrl = (el, title, description) => {
  const titleValue = el.attributes[title].toLowerCase().replace(/\s+/g, "-");
  const blogValue = el.attributes[description]
    ?.toLowerCase()
    .replace(/\s+/g, "-");

  return blogValue ? `${titleValue}-${blogValue}` : titleValue;
};

export default createSlugUrl;
