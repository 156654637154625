import React, { useEffect } from "react";
import cheerio from "cheerio";
import { Link, useParams } from "react-router-dom";
import formatDate from "../../utils/FormatDate";
import Footer from "../Footer/Footer";
import "./BlogDetails.scss";
import NavbarWrapper from "../Navbar/Navbar";
import LinkForm from "../LinkForm/LinkForm";
import Blogs from "../Blogs/Blogs";
import calendarIcon from "../../assets/images/calendar.png";
import authorIcon from "../../assets/images/author.png";
import back from "../../assets/images/back-to-blogs.svg";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Helmet } from "react-helmet";

const wrapConsecutiveFiguresInCarousel = (htmlContent) => {
  const $ = cheerio.load(htmlContent);
  const figures = $("figure");

  let figuresToWrap = [];
  let wrapper;

  function generateRandomClassName() {
    return Math.floor(1000000000 + Math.random() * 9000000000).toString();
  }

  figures.each((index, figure) => {
    const currentFigure = $(figure);
    const nextFigure = currentFigure.next("figure");

    figuresToWrap.push(currentFigure);

    if (!nextFigure.length || nextFigure.prev().get(0) !== figure) {
      if (figuresToWrap.length > 1) {
        const randomClassName = generateRandomClassName();
        wrapper = $(
          `<div class="carousel swiper-container scarousel-${randomClassName}"></div>`
        );
        const swiperWrapper = $('<div class="swiper-wrapper"></div>');

        figuresToWrap.forEach((figureElement) => {
          const swiperSlide = $('<div class="swiper-slide"></div>').append(
            figureElement.clone()
          );
          swiperWrapper.append(swiperSlide);
        });

        wrapper.append(swiperWrapper);
        figuresToWrap[0].before(wrapper);
        figuresToWrap.forEach((figureElement) => {
          figureElement.remove();
        });

        figuresToWrap = [];
      } else {
        figuresToWrap = [];
      }
    }
  });

  return $.html();
};

const BlogDetails = ({ postData }) => {
  const { id } = useParams();
  const post = postData.find((item) => item.slug === id);

  useEffect(() => {
    if (post) {
      const swiperElements = document.querySelectorAll('[class^="carousel"]');

      const classNamesArray = Array.from(swiperElements)
        .flatMap((element) => Array.from(element.classList))
        .filter((className) => className.startsWith("scarousel-"));

      for (let i = 0; i < swiperElements.length; i++) {
        new Swiper(`.${classNamesArray[i]}`, {
          draggable: true,
          slidesPerView: 1,
          spaceBetween: 10,
          loop: false,
        });
      }
    }
  }, [post]);

  if (!post) {
    return <div>Post not found</div>;
  }

  const formattedDate = formatDate(post.date);
  const dateOnly = formattedDate.replace(/^(.*?)\s.*$/, "$1");

  const keywords = post.yoast_head_json.schema["@graph"][0].keywords || [];

  const processedContent = wrapConsecutiveFiguresInCarousel(
    post.content.rendered
  );

  const parseMetaTag = (metaTag) => {
    const $ = cheerio.load(metaTag);
    const meta = $("meta").first();

    if (meta && meta.attr("name")) {
      return {
        name: meta.attr("name"),
        content: meta.attr("content") || "",
      };
    }

    return {
      property: meta.attr("property") || "",
      content: meta.attr("content") || "",
    };
  };

  return (
    <>
      <Helmet>
        {post.yoast_head.map((metaTag, index) => {
          const parsedMeta = parseMetaTag(metaTag);
          return <meta key={index} {...parsedMeta} />;
        })}
      </Helmet>

      <NavbarWrapper />
      <div className="blog-details">
        <div className="blog-details-container">
          <div className="blog-header-img">
            {post.yoast_head_json.og_image &&
              post.yoast_head_json.og_image[0] && (
                <img src={post.yoast_head_json.og_image[0].url} alt="" />
              )}
            <Link to="/blogs">
              <img src={back} className="back-to-blogs" alt="back-to-blogs" />
            </Link>
          </div>
          <div className="post-container">
            <div className="blog-tags">
              {keywords.map((keyword, id) => (
                <div className="blog-tag" key={id}>
                  {keyword}
                </div>
              ))}
            </div>
            <div className="blog-date">
              <img src={authorIcon} alt="" />{" "}
              {`By ${post.yoast_head_json.author}`}
              <img src={calendarIcon} alt="" /> {dateOnly}
            </div>
            <h1 className="blog-title">{post.title.rendered}</h1>
            <div dangerouslySetInnerHTML={{ __html: processedContent }} />
          </div>
        </div>
      </div>

      <Blogs postData={postData} id={id} />
      <LinkForm />
      <Footer />
    </>
  );
};

export default BlogDetails;
