import React, { useState } from "react";
import Popup from "reactjs-popup";
import "./Navbar.scss";
import saraniLogoDark from "../../assets/images/sarani-logo-dark.svg";
import saraniLogoLight from "../../assets/images/sarani-logo-light.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const LANGUAGES = ["EN", "FR", "AR"];
const LINKS = [
  "how-we-work",
  "projects",
  // "blogs",
  "pricing",
  "about",
  "media",
  "contact",
];

const Navbar = ({
  isOpen,
  toggleBurgerMenu,
  language,
  setLanguage,
  mode,
  burgerMode,
}) => {
  const { t } = useTranslation();

  function handleClick(lang) {
    i18next.changeLanguage(lang);
    setLanguage(lang);
  }

  const logoMode = mode === "light" ? saraniLogoLight : saraniLogoDark;
  const burgerMenuMode =
    burgerMode === "light" ? "burger-menu burger-light" : "burger-menu";

  return (
    <div className="navbar">
      <NavLink to="/">
        <img className="logo" src={logoMode} alt="logo" />
      </NavLink>
      <div
        className={`${burgerMenuMode} ${isOpen ? "open" : ""}`}
        onClick={() => {
          toggleBurgerMenu(!isOpen);
        }}
      >
        <div className="bar1" key="b1" />
        <div className="bar2" key="b2" />
        <div className="bar3" key="b3" />
      </div>
      <Popup
        open={isOpen}
        onClose={(e) => {
          toggleBurgerMenu(false);
        }}
        closeOnDocumentClick={false}
        modal
        overlayStyle={{
          background: "#0bb3f0",
        }}
        contentStyle={{
          margin: "50px auto",
          width: "70%",
        }}
      >
        <div className="menu">
          <div className="languages">
            {LANGUAGES.map((lang, id) => (
              <div
                onClick={() => handleClick(lang.toLowerCase())}
                key={id}
                className={`lang ${
                  language.toLowerCase() === lang.toLowerCase() ? "active" : ""
                }`}
              >
                {lang}
              </div>
            ))}
          </div>
          <div className="columns-container">
            <ul className="column">
              {Object.entries(t("navbar_categories", { returnObjects: true }))
                .slice(0, 3) // Select the first three categories
                .map((category, id) => (
                  <NavLink
                    key={id}
                    to={`/${LINKS[id]}`}
                    onClick={() => toggleBurgerMenu(false)}
                  >
                    <li>{category[1]}</li>
                  </NavLink>
                ))}
            </ul>

            <ul className="column">
              {Object.entries(t("navbar_categories", { returnObjects: true }))
                .slice(3)
                .map((category, id, array) =>
                  id === array.length - 1 ? (
                    <li key={id + 3}>
                      <a
                        href="https://1d4o4gdt2w1.typeform.com/to/mIpgxGDj"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#fff" }}
                      >
                        {category[1]}
                      </a>
                    </li>
                  ) : (
                    <NavLink
                      key={id + 3}
                      to={`/${LINKS[id + 3]}`}
                      onClick={() => toggleBurgerMenu(false)}
                    >
                      <li>{category[1]}</li>
                    </NavLink>
                  )
                )}
            </ul>
          </div>
        </div>
      </Popup>
    </div>
  );
};

const NavbarWrapper = ({ mode, burgerMode }) => {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const lang = i18next.language;
  const transformedLang = lang.split("-")[0].toUpperCase();

  const stateLang =
    transformedLang === "FR" || transformedLang === "AR"
      ? transformedLang
      : "EN";

  const [language, setLanguage] = useState(stateLang);

  const toggleBurgerMenu = (open) => {
    if (open !== burgerMenuOpen) {
      setBurgerMenuOpen(open);
    }
  };

  return (
    <Navbar
      isOpen={burgerMenuOpen}
      toggleBurgerMenu={toggleBurgerMenu}
      language={language}
      setLanguage={setLanguage}
      mode={mode}
      burgerMode={burgerMode}
    />
  );
};

export default NavbarWrapper;
