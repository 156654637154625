import React, { useState, useEffect } from "react";
import "./MovingArrow.scss";

const MovingArrow = () => {
  const [isAnimating, setIsAnimating] = useState(true);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsHidden(true);
        setIsAnimating(false);
      } else {
        setIsHidden(false);
        setIsAnimating(true);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`moving-arrow ${isAnimating ? "animate" : ""} ${
        isHidden ? "hidden" : ""
      }`}
    >
      <span className="moving-arrow-icon">&#8595;</span>
    </div>
  );
};

export default MovingArrow;
