import React from "react";
import useIsMobile from "../../hooks/useIsMobile";
import baseURL from "../../url/baseUrl";
import "./ProjectsList.scss";
import i18next from "i18next";

const ProjectItem = ({ data }) => {
  const isMobile = useIsMobile();
  const heroImg = data.Carousel_image.data.attributes.url;
  const mobileHeroImg = data.Carousel_image.data.attributes.url;

  const imgUrl = isMobile
    ? `${baseURL}${mobileHeroImg}`
    : `${baseURL}${heroImg}`;

  const getProjectData = (data) => {
    const language = i18next.language;

    switch (language) {
      case "ar":
        return [
          data.AR_Project_title || data.Project_title,
          data.AR_Project_description || data.Project_description,
        ];
      case "fr":
      case "fr-FR":
        return [
          data.Project_title,
          data.FR_Project_description || data.Project_description,
        ];
      case "en":
      case "en-US":
      default:
        return [data.Project_title, data.Project_description];
    }
  };

  const translatedData = getProjectData(data);

  return (
    <div className="project-container">
      <div className="project-container-hero">
        <img src={imgUrl} alt="hero-img" />
      </div>
      {/* <h4>{translatedData[0]}</h4>
      <h5>{translatedData[1]}</h5> */}
    </div>
  );
};

export default ProjectItem;
