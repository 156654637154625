import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./Work.scss";
import Testimonials from "../Testimonials/Testimonials";
import useIsMobile from "../../hooks/useIsMobile";
import TestimonialRenderer from "../Testimonials/TestimonialRenderer";

const Work = ({ doc }) => {
  const { t } = useTranslation();

  const items = Object.entries(t("our_work.items", { returnObjects: true }));

  const breakpoints = {
    576: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 1,
    },
    920: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 3,
    },
  };

  const isMobile = useIsMobile();
  const swiperRef = useRef(null);
  useEffect(() => {
    if (isMobile) {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.destroy(true, true);
      }
    }
  }, [isMobile]);

  return (
    <>
      <div className="work">
        <div className="work-container">
          <h1>{t("our_work.title")}</h1>
          <h1>{t("our_work.title2")}</h1>
          <div className="work-desc">{t("our_work.description")}</div>
          <div className="work-items">
            <div className="work-arr">
              <img
                src={require("../../assets/images/our-work/left.png")}
                alt=""
              />
            </div>
            {[...Array(2)].map((_, squareIndex) => (
              <div className="work-items-square" key={`square-${squareIndex}`}>
                <div className="work-arr">
                  <img
                    src={require("../../assets/images/our-work/left.png")}
                    alt=""
                  />
                </div>
                {[...Array(2)].map((_, pairIndex) => (
                  <div
                    className="work-items-pair"
                    key={`pair-${squareIndex}-${pairIndex}`}
                  >
                    <div className="work-arr">
                      <img
                        src={require("../../assets/images/our-work/right.png")}
                        alt=""
                      />
                    </div>
                    {items
                      .slice(
                        (squareIndex * 2 + pairIndex) * 2,
                        (squareIndex * 2 + pairIndex + 1) * 2
                      )
                      .map(([key, el]) => (
                        <div key={key} className="work-item">
                          <img
                            src={require(`../../assets/images/our-work/${el.img}`)}
                            alt=""
                          />
                          <h2>{el.title}</h2>
                          <span>{el.text}</span>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="work-bg" />
      </div>
      <div className="customers">
        <div className="customers-bg" />
        <div className="customers-container">
          <h1>{t("our_work.customers")}</h1>
          <div className="customers-items">
            {Object.entries(
              t("our_work.customers_items", { returnObjects: true })
            ).map((el, id) => (
              <div key={id} className="customers-item">
                <h2>{el[1][0]}</h2>
                <span>{el[1][1]}</span>
              </div>
            ))}
          </div>
          <TestimonialRenderer
            testimonials={t("testimonials_cards", { returnObjects: true })}
            isMobile={isMobile}
            breakpoints={breakpoints}
          />
        </div>
      </div>
    </>
  );
};

export default Work;
