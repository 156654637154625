import React from "react";
import "./Filters.scss";
import { useTranslation } from "react-i18next";

const Filters = ({ filters, activeFilters, onFilterClick }) => {
  const { t } = useTranslation();

  const translatedFilters = Object.entries(
    t("projects_filters", { returnObjects: true })
  ).map((filter) => filter[1]);

  return (
    <div className="filters">
      {filters.map((filter, idx) => (
        <div
          key={filter.name}
          onClick={() => onFilterClick(filter.name)}
          className={`filter ${
            activeFilters.includes(filter.name) ? "active" : ""
          }`}
        >
          {translatedFilters[idx]}
        </div>
      ))}
    </div>
  );
};

export default Filters;
