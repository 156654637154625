import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import createSlugUrl from "../../utils/createSlugUrl";
import { v4 as uuidv4 } from "uuid";
import Filters from "../Filters/Filters";
import Subfilters from "../Subfilters/Subfilters";
import ProjectItem from "./ProjectItem";
import { useTranslation } from "react-i18next";
import "./ProjectsList.scss";
import { videoData } from "../../constants/arrays";

const PROJECTS_FILTERS = [
  {
    name: "all",
    subfilters: [
      "logos",
      "brand guidelines",
      "brand identity",
      "online assets",
      "offline assets",
      "exhibition booth",
      "brochures",
      "leaflets",
      "magazines",
      "market research",
      "whitepapers",
      "pitch deck",
      "presentations",
      "social banners",
      "social account management",
      "affiliation",
      "paid media",
      "2D",
      "3D",
      "animation",
      "corporate",
      "promotion",
      "social media",
      "landing pages",
      "newsletters",
      "websites",
      "virtualization",
    ],
  },
  {
    name: "branding",
    subfilters: ["logos", "brand guidelines", "brand identity"],
  },
  {
    name: "graphic design",
    subfilters: ["online assets", "offline assets", "exhibition booth"],
  },
  {
    name: "marketing assets",
    subfilters: [
      "brochures",
      "leaflets",
      "magazines",
      "market research",
      "whitepapers",
    ],
  },
  {
    name: "presentations",
    subfilters: ["pitch deck", "presentations"],
  },
  {
    name: "photos",
    subfilters: [],
  },
  {
    name: "social media",
    subfilters: [
      "social banners",
      "social account management",
      "affiliation",
      "paid media",
    ],
  },
  {
    name: "videos",
    subfilters: [
      "2D",
      "3D",
      "animation",
      "corporate",
      "promotion",
      "social media",
    ],
  },
  {
    name: "web",
    subfilters: ["landing pages", "newsletters", "websites", "virtualization"],
  },
];

const SHOWN_PROJECTS = 500;

const Projects = ({
  data,
  projectsToShow,
  setProjectsToShow,
  activeFilters,
}) => {
  const { t } = useTranslation();

  const videoRefs = useRef([]);

  useEffect(() => {
    videoRefs.current.forEach((video) => {
      if (video) {
        video.currentTime = 3;
        video.addEventListener(
          "seeked",
          () => {
            const canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            video.poster = canvas.toDataURL("image/jpeg");
          },
          { once: true }
        );
      }
    });
  }, [data, projectsToShow]);

  const photoStyles = [
    [1500, 1000],
    [2048, 1367],
    [1200, 1200],
    [1200, 800],
    [1766, 1179],
    [1290, 861],
    [2048, 1365],
    [3333, 5000],
    [1917, 2573],
    [5000, 3337],
    [3333, 5000],
    [2147, 3227],
    [1150, 2048],
    [1500, 1000],
    [5000, 3333],
    [1200, 800],
    [2048, 1365],
    [2048, 1365],
    [5000, 3333],
    [2048, 1363],
    [1363, 2048],
    [1200, 1200],
  ];

  return (
    <>
      <div
        className={
          activeFilters.includes("photos")
            ? "projects-photos-table"
            : "projects-grid-table"
        }
      >
        {data.slice(0, projectsToShow).map((item, index) => {
          const uniqueKey = `${item.id}-${uuidv4()}`;
          if (item.isPhoto) {
            return (
              <div
                className="photo-container"
                key={uniqueKey}
                style={{
                  flexBasis:
                    (photoStyles[index][0] / photoStyles[index][1]) * 300,
                  flexGrow:
                    (photoStyles[index][0] / photoStyles[index][1]) * 100,
                }}
              >
                <img src={item.url} alt={`Photo ${index + 1}`} />
              </div>
            );
          } else if (item.url) {
            return (
              <div className="video-container" key={uniqueKey}>
                <video controls ref={(el) => (videoRefs.current[index] = el)}>
                  <source src={item.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            );
          }
          const link = createSlugUrl(
            item,
            "Project_title",
            "Project_description"
          );
          return (
            <Link to={`/projects/${link}`} state={{ item }} key={item.id}>
              <ProjectItem data={item.attributes} />
            </Link>
          );
        })}
        {data.length > projectsToShow && (
          <div
            className="projects-button"
            onClick={() => setProjectsToShow(projectsToShow + SHOWN_PROJECTS)}
          >
            {t("viewMore.0")}
          </div>
        )}
      </div>
      {data.length === 0 && (
        <div style={{ color: "#111111" }} className="no-projects">
          No projects yet with selected filters...
        </div>
      )}
    </>
  );
};

const ProjectsList = ({ data }) => {
  const [projectsToShow, setProjectsToShow] = useState(SHOWN_PROJECTS);
  const navigate = useNavigate();

  let subFiltersFromUrl = ["all"];
  let filtersFromUrl = ["all"];
  let urlSearchParams;

  if ("window" in global) {
    urlSearchParams = new URLSearchParams(window.location.search);
    filtersFromUrl = urlSearchParams.get("filter")?.split(",") || ["all"];
    subFiltersFromUrl = urlSearchParams.get("subfilter")?.split(",") || ["all"];
  }

  const [showAllSubfilters, setShowAllSubfilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState(filtersFromUrl);
  const [activeSubFilters, setActiveSubFilters] = useState(subFiltersFromUrl);

  console.log(activeFilters);

  const { t } = useTranslation();

  const handleFilterClick = (filter) => {
    if (filter === "photos") {
      setActiveFilters(["photos"]);
      navigate("/projects?filter=photos");
    } else if (filter === "all") {
      setActiveFilters(["all"]);
      navigate("/projects");
    } else {
      setActiveFilters([filter]);
      navigate(`/projects?filter=${filter}`);
    }
    setProjectsToShow(SHOWN_PROJECTS);
  };

  const handleSubFilterClick = (subfilter) => {
    const filterFromUrl = urlSearchParams?.get("filter") || "all";
    const subfiltersFromUrl = urlSearchParams?.get("subfilter")?.split(",") || [
      "all",
    ];

    if (subfilter === "all") {
      setActiveSubFilters(["all"]);
      navigate(`/projects?filter=${filterFromUrl}&subfilter=all`);
    } else {
      if (subfiltersFromUrl.includes("all")) {
        setActiveSubFilters([subfilter]);
        navigate(`/projects?filter=${filterFromUrl}&subfilter=${subfilter}`);
      } else if (subfiltersFromUrl.includes(subfilter)) {
        const newSubfilters = subfiltersFromUrl.filter((f) => f !== subfilter);
        if (newSubfilters.length === 0) {
          setActiveSubFilters(["all"]);
          navigate(`/projects?filter=${filterFromUrl}&subfilter=all`);
        } else {
          setActiveSubFilters(newSubfilters);
          navigate(
            `/projects?filter=${filterFromUrl}&subfilter=${newSubfilters.join(
              ","
            )}`
          );
        }
      } else {
        setActiveSubFilters([...subfiltersFromUrl, subfilter]);
        navigate(
          `/projects?filter=${filterFromUrl}&subfilter=${[
            ...subfiltersFromUrl,
            subfilter,
          ].join(",")}`
        );
      }
    }
    setProjectsToShow(SHOWN_PROJECTS);
  };

  if (activeFilters.length === 0) setActiveFilters(["all"]);

  const filterData = () => {
    let filteredData = data;

    if (activeFilters.includes("all") && !activeFilters.includes("photos")) {
      filteredData = data.filter((item) => !item.url && !item.isPhoto);
    } else {
      if (!activeFilters.includes("all") && !activeFilters.includes("photos")) {
        filteredData = data.filter((item) =>
          activeFilters.every((filter) =>
            item.attributes.Filters?.includes(filter)
          )
        );
      }

      if (!activeSubFilters.includes("all")) {
        filteredData = filteredData.filter((item) =>
          activeSubFilters.every((subfilter) =>
            item.attributes.Subfilters?.includes(subfilter)
          )
        );
      }

      if (activeFilters.includes("videos")) {
        filteredData = [...videoData, ...filteredData];
      }

      if (activeFilters.includes("photos")) {
        filteredData = Array.from({ length: 22 }, (_, index) => ({
          id: `photo-${index + 1}`,
          isPhoto: true,
          attributes: { Filters: ["photos"] },
          url: `${process.env.PUBLIC_URL}/photos/${index + 1}.webp`,
        }));
      }
    }

    return filteredData;
  };

  const handleResetFilters = () => {
    setActiveFilters(["all"]);
    setActiveSubFilters(["all"]);
    navigate("/projects");
    setProjectsToShow(SHOWN_PROJECTS);
  };

  const subFilters = PROJECTS_FILTERS.filter((filter) =>
    activeFilters.includes(filter.name)
  ).flatMap((filter) => filter.subfilters);
  subFilters.unshift("all");

  const translatedSubFilters = Object.entries(
    t("projects_subfilters", { returnObjects: true })
  ).map((subfilter) => subfilter[1]);

  const filteredTranslatedSubFilters = translatedSubFilters
    .filter((filter) => activeFilters.includes(Object.keys(filter)[0]))
    .flatMap((filter) => Object.values(filter)[0]);
  filteredTranslatedSubFilters.unshift(t("projects_subfilters_all.0"));

  return (
    <>
      <div className="filters-group">
        <div className="filters-block">
          <Filters
            filters={PROJECTS_FILTERS}
            activeFilters={activeFilters}
            onFilterClick={handleFilterClick}
          />
        </div>
        <div className="clear-btn" onClick={handleResetFilters}>
          {t("show.clear")}
        </div>
      </div>
      <Projects
        data={filterData()}
        projectsToShow={projectsToShow}
        setProjectsToShow={setProjectsToShow}
        activeFilters={activeFilters}
      />
    </>
  );
};

export default ProjectsList;
