import React, { useState } from "react";
import { Link } from "react-router-dom";
import formatDate from "../../utils/FormatDate";
import "./Blogs.scss";
import { useTranslation } from "react-i18next";

const extractImageUrl = (excerpt) => {
  const match = excerpt.match(/https:\/\/[^<>\s]+/);
  return match ? match[0] : null;
};

const formatTitle = (slug) => {
  return slug
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const formatDateOnly = (date) => {
  const formattedDate = formatDate(date);
  return formattedDate.replace(/^(.*?)\s.*$/, "$1");
};

const renderBlogCard = (item, key, excludeId) => {
  if (item.slug === excludeId) {
    return null;
  }

  const heroImg = item.excerpt.rendered;
  const extractedLink = extractImageUrl(heroImg);
  const title = formatTitle(item.slug);
  const dateOnly = formatDateOnly(item.date);
  const keywords = item.yoast_head_json.schema["@graph"][0].keywords;

  return (
    <Link to={`/blogs/${item.slug}`} key={key}>
      <div className="blogs-card">
        {extractedLink ? (
          <img src={extractedLink} alt="" />
        ) : (
          <div className="blog-item-bg" />
        )}
        <h3>{title}</h3>
        <span>{dateOnly}</span>
        <div className="blog-tags">
          {keywords?.slice(0, 2).map((keyword, id) => (
            <div className="blog-tag" key={id}>
              {keyword}
            </div>
          ))}
        </div>
      </div>
    </Link>
  );
};

const Blogs = ({ postData, id }) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();

  const sortedPosts = [...postData].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const limitedPosts = sortedPosts
    .filter((item) => item.slug !== id)
    .slice(0, 3)
    .map((item) => renderBlogCard(item, item.id));

  const allPosts = sortedPosts
    .filter((item) => item.slug !== id)
    .map((item) => renderBlogCard(item, item.id));

  const postsToShow = showAll ? allPosts : limitedPosts;

  const handleClick = () => {
    setShowAll(true);
  };

  return (
    <div className="blogs">
      <div className="blogs-container">{postsToShow}</div>
      {sortedPosts.length > 4 && !showAll && (
        <div onClick={handleClick} className="projects-button blogs-button">
          {t("viewMore.0")}
        </div>
      )}
    </div>
  );
};

export default Blogs;
