import React from "react";
import expertiseImage0 from "../../assets/images/expertise/1.png";
import expertiseImage1 from "../../assets/images/expertise/2.png";
import expertiseImage2 from "../../assets/images/expertise/3.png";
import expertiseImage3 from "../../assets/images/expertise/4.png";
import expertiseImage4 from "../../assets/images/expertise/5.png";

const ExpertiseItem = ({ itemTitle, itemSubtitles, id }) => {
  const expertiseImages = [
    expertiseImage0,
    expertiseImage1,
    expertiseImage2,
    expertiseImage3,
    expertiseImage4,
  ];

  return (
    <div className="expertise-item">
      <img src={expertiseImages[id]} alt={`Expertise ${id}`} />
      <div className="expertise-item-title">{itemTitle}</div>
      <div className="expertise-item-filters">
        {itemSubtitles[0].map((el) => (
          <span key={el}>{el}</span>
        ))}
      </div>
    </div>
  );
};

export default ExpertiseItem;
