import React from "react";
import { useTranslation } from "react-i18next";
import "./Terms.scss";
import NavbarWrapper from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const TermItem = ({ title, content }) => (
  <div className="terms-item">
    <h6>{title}</h6>
    {Array.isArray(content) ? (
      <div className="paragh-items terms-text">
        {content.map((paragraph, index) => (
          <div className="paragh-item" key={index}>
            {paragraph && typeof paragraph === "object" && paragraph.bold && (
              <strong>
                <span>{paragraph.bold}</span>
              </strong>
            )}
            {paragraph && typeof paragraph === "object" ? (
              <span dangerouslySetInnerHTML={{ __html: paragraph.text }} />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: paragraph }} />
            )}
          </div>
        ))}
      </div>
    ) : (
      <div dangerouslySetInnerHTML={{ __html: content }} />
    )}
  </div>
);

const Terms = ({ doc }) => {
  const { t } = useTranslation();

  const termsParagraphs = t(`${doc}.paragraphs`, {
    returnObjects: true,
  });

  return (
    <>
      <NavbarWrapper />
      <div className="terms">
        <div className="terms-container">
          <h1>{t(`${doc}.title`)}</h1>
          <div className="terms-items">
            <div className="terms-text">{t(`${doc}.intro`)}</div>
            {Object.entries(termsParagraphs).map(([key, value], id) => (
              <TermItem key={id} title={value[0]} content={value.slice(1)} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
