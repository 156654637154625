import React from "react";

const Fallback = ({ message, component }) => {
  const isComponent = React.isValidElement(component);

  return (
    <>
      {isComponent ? component : null}
      <div className="fallback">
        <div>{message}</div>
      </div>
    </>
  );
};

export default Fallback;