import React from "react";
import Footer from "../components/Footer/Footer";
import OurProject from "../components/OurProject/OurProject";
import LinkForm from "../components/LinkForm/LinkForm";
import NavbarWrapper from "../components/Navbar/Navbar";

const OurProjects = ({ data }) => {
  return (
    <>
      <NavbarWrapper />
      <OurProject data={data} />
      <LinkForm />
      <Footer />
    </>
  );
};

export default OurProjects;
