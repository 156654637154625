import React from "react";

import "./About.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import BrandBanner from "../BrandBanner/BrandBanner";

const About = () => {
  const { t } = useTranslation();

  const numbers =
    i18next.language === "ar"
      ? [t("about_numbers.0"), t("about_numbers.1"), t("about_numbers.2")]
      : ["50+", "2000+", "5"];
  return (
    <>
      <div className="statistic">
        <div className="statistic-container">
          <div>
            <span>{numbers[0]}</span>
            <div>{t("about_statistic.0")}</div>
          </div>
          <div>
            <span>{numbers[1]}</span>
            <div>{t("about_statistic.1")}</div>
          </div>
          <div>
            <span>{numbers[2]}</span>
            <div>
              {t("about_statistic.2")}
              <br /> {t("about_statistic.3")}
            </div>
          </div>
        </div>
        <BrandBanner />
      </div>
    </>
  );
};

export default About;
