import React from "react";
import AgencyDeck from "../components/AgencyDeck/AgencyDeck";
import Footer from "../components/Footer/Footer";
import NavbarWrapper from "../components/Navbar/Navbar";
import HeroVideo from "../components/HeroVideo/HeroVideo";
import Team from "../components/Team/Team";
import TeamMembers from "../components/TeamMembers/TeamMembers";
import AddedValue from "../components/AddedValue/AddedValue";
import Expertise from "../components/Expertise/Expertise";
import About from "../components/About/About";
import LinkForm from "../components/LinkForm/LinkForm";

const AboutUs = () => {
  return (
    <>
      <NavbarWrapper mode="light" burgerMode="light" />
      <HeroVideo padTop="padTop" />
      <TeamMembers />
      <AddedValue />
      <Expertise />
      <About />
      <Team />
      <AgencyDeck />
      <LinkForm />
      <Footer />
    </>
  );
};

export default AboutUs;
