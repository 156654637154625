import React from "react";
import { Routes, Route } from "react-router-dom";
import AboutUs from "../pages/AboutUs";
import HomePage from "../pages/HomePage";
import ContactUs from "../pages/ContactUs";
import OurProjects from "../pages/OurProjects";
import Fallback from "../pages/Fallback";
import ProjectDetails from "../components/ProjectDetails/ProjectDetails";
import OurBlogs from "../pages/OurBlogs";
import BlogDetails from "../components/BlogDetails/BlogDetails";
import Terms from "../components/Terms/Terms";
import ScrollToTop from "../utils/ScrollToTop";
import "./App.scss";
import HowWeWork from "../pages/HowWeWork";
import MediaPage from "../pages/Media";
import Prices from "../pages/Prices";

function App(props) {
  const { error, projectsData, postsData } = props;
  if (error) {
    return <Fallback message={`Error: the data was not fetched...`} />;
  }

  const reversedProjects = projectsData ? [...projectsData.data].reverse() : [];
  const reversedPosts = postsData ? [...postsData].reverse() : [];

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={projectsData && <HomePage data={reversedProjects} />}
        />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/projects">
          <Route
            index
            element={projectsData && <OurProjects data={reversedProjects} />}
          />
          <Route
            path=":id"
            element={projectsData && <ProjectDetails data={reversedProjects} />}
          />
        </Route>
        <Route path="/blogs">
          <Route
            index
            element={postsData && <OurBlogs postData={reversedPosts} />}
          />
          <Route
            path=":id"
            element={postsData && <BlogDetails postData={reversedPosts} />}
          />
        </Route>
        <Route path="/privacy-policy" element={<Terms doc="policy" />} />
        <Route path="/terms-conditions" element={<Terms doc="terms" />} />
        <Route path="/pricing" element={<Prices />} />
        <Route path="/how-we-work" element={<HowWeWork />} />
        <Route
          path="/media"
          element={projectsData && <MediaPage postData={reversedPosts} />}
        />
      </Routes>
    </div>
  );
}

export default App;
