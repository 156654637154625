import React from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import LinkForm from "../components/LinkForm/LinkForm";
import Pricing from "../components/Pricing/Pricing";

const Prices = () => {
  return (
    <>
      <Navbar />
      <Pricing />
      <LinkForm />
      <Footer />
    </>
  );
};

export default Prices;
