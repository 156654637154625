import React, { useState } from "react";
import ContactImg from "../../assets/images/contact.svg";
import { useTranslation } from "react-i18next";
import "./FAQ.scss";

const FAQ = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(null);

  const { t } = useTranslation();

  const handleItemClick = (index) => {
    setActiveItemIndex(activeItemIndex === index ? null : index);
  };

  const accordionItems = Object.entries(
    t("faq_items", { returnObjects: true })
  ).map((item, index) => (
    <div key={item[1].id} className="accordion-item">
      <button
        id={`accordion-button-${item[1].id}`}
        className="accordion-button"
        aria-expanded={activeItemIndex === index ? "true" : "false"}
        onClick={() => handleItemClick(index)}
      >
        <span className="accordion-title">{item[1].title}</span>
        <span className="icon" aria-hidden="true"></span>
      </button>
      {activeItemIndex === index && (
        <div className="accordion-content">{item[1].content}</div>
      )}
    </div>
  ));

  return (
    <div className="faq" id="faq">
      <div className="faq-gradient" />
      <div className="faq-container">
        <h1>{t("faq.0")}</h1>
        <div className="faq-content" id="faq">
          <img src={ContactImg} alt="" />
          <div className="container">
            <div className="accordion">{accordionItems}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
