import React from "react";
import Footer from "../components/Footer/Footer";
import HeroVideo from "../components/HeroVideo/HeroVideo";
import Home from "../components/Home/Home";
import Navbar from "../components/Navbar/Navbar";
import Purpose from "../components/Purpose/Purpose";
import Testimonials from "../components/Testimonials/Testimonials";
import Metrics from "../components/Metrics/Metrics";
import Ready from "../components/Ready/Ready";
import LinkForm from "../components/LinkForm/LinkForm";
import FAQ from "../components/FAQ-home/FAQ";

const HomePage = ({ data }) => {
  return (
    <>
      <Navbar />
      <Home />
      <HeroVideo />
      <Purpose />
      <Metrics />
      <Ready data={data} />
      <Testimonials />
      <FAQ />
      <LinkForm />
      <Footer />
    </>
  );
};

export default HomePage;
