import React from "react";
import Footer from "../components/Footer/Footer";
import OurBlog from "../components/OurBlog/OurBlog";
import LinkForm from "../components/LinkForm/LinkForm";
import NavbarWrapper from "../components/Navbar/Navbar";

const OurBlogs = ({ postData }) => {
  return (
    <>
      <NavbarWrapper />
      <OurBlog postData={postData} />
      <LinkForm />
      <Footer />
    </>
  );
};

export default OurBlogs;
