import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import FAQ from "../components/FAQ/FAQ";
import Footer from "../components/Footer/Footer";
import NavbarWrapper from "../components/Navbar/Navbar";
import LinkForm from "../components/LinkForm/LinkForm";

const ContactUs = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#faq") {
      const faqElement = document.getElementById("faq");
      faqElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <>
      <NavbarWrapper mode="light" burgerMode="light" />
      <div style={{ width: "100%", height: "100px", background: "#111" }} />
      <LinkForm />
      <FAQ />
      <Footer />
    </>
  );
};

export default ContactUs;
