import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Media.scss";
import { Link, NavLink } from "react-router-dom";
import BlogItem from "../BlogList/BlogItem";

const Media = ({ postData = [] }) => {
  const { t } = useTranslation();
  const numToShow = 4;

  const items = Object.entries(t("our_work.items", { returnObjects: true }));

  const sortedPosts = [...postData].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const posts = sortedPosts.slice(0, numToShow).map((post, id) => {
    const heroImg = post?.excerpt.rendered;
    const match = heroImg.match(/https:\/\/[^<>\s]+/);
    const extractedLink = match ? match[0] : null;

    const title = post.slug
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    const author = `By ${post.yoast_head_json.author}`;
    return (
      <div key={id} className="media-blog-item">
        <Link to={`/blogs/${post.slug}`} key={id}>
          {extractedLink ? (
            <img src={extractedLink} alt="" />
          ) : (
            <div className="blog-item-bg" />
          )}
        </Link>
      </div>
    );
  });

  return (
    <>
      <div className="media">
        <div className="media-container">
          <h1>{t("media.title")}</h1>
          <div className="media-desc">{t("media.description")}</div>

          <div className="media-items">
            {/* item 1 */}
            <div className="media-item">
              <div>
                <h2>{t("media.friday.title")}</h2>
                <div className="media-desc">{t("media.friday.text")}</div>
              </div>

              <div className="media-categories-row">
                {Object.entries(
                  t("media.friday.items", { returnObjects: true })
                ).map((el, id, array) => (
                  <div key={id} className="media-categories-item">
                    <img
                      src={require(`../../assets/images/media/${el[1].img}`)}
                      alt=""
                      className={`${id === 0 ? "border-left-top-bottom" : ""} ${
                        id === array.length - 1 ? "border-right-top-bottom" : ""
                      }`}
                    />
                    <h3>{el[1].title}</h3>
                    <span>{el[1].text}</span>
                  </div>
                ))}
              </div>
              <div className="media-subscribe media-text">
                {t("media.friday.subscribe")}
              </div>
              <a
                href="https://1d4o4gdt2w1.typeform.com/to/mIpgxGDj"
                target="_blank"
                rel="noreferrer"
                className="linkform-btn"
                style={{ margin: "0 auto" }}
              >
                {t("media.friday.btn")}
              </a>

              <div className="media-day">
                <h2>{t("media.friday.day")}</h2>
                <a
                  href="https://1d4o4gdt2w1.typeform.com/to/mIpgxGDj"
                  target="_blank"
                  rel="noreferrer"
                  className="media-btn linkform-btn"
                >
                  {t("media.friday.btn")}
                </a>
              </div>
            </div>

            {/* item 2 */}
            {/* <div className="media-item media-item-bg">
              <div>
                <h2>{t("media.thursday.title")}</h2>
                <div className="media-desc">{t("media.thursday.text")}</div>
              </div>
              <div className="media-categories-row-2">
                <div className="media-categories-col">
                  {Object.entries(
                    t("media.thursday.items", { returnObjects: true })
                  ).map((el, id) => (
                    <div key={id} className="media-categories-item">
                      <h3>{el[1].title}</h3>
                      <span>{el[1].text}</span>
                    </div>
                  ))}
                  <div className="media-subscribe media-text">
                    {t("media.thursday.listen")}
                  </div>
                  <a
                    href="https://1d4o4gdt2w1.typeform.com/to/mIpgxGDj"
                    target="_blank"
                    rel="noreferrer"
                    className="linkform-btn"
                  >
                    {t("media.thursday.btn")}
                  </a>
                </div>

                <div className="media-categories-col">
                  <img
                    src={require("../../assets/images/media/episode1.png")}
                    alt=""
                  />
                  <div className="img-row">
                    <img
                      src={require("../../assets/images/media/episode2.png")}
                      alt=""
                    />
                    <img
                      src={require("../../assets/images/media/episode3.png")}
                      alt=""
                    />
                  </div>
                  <div className="episode-btn">
                    <a href="https://1d4o4gdt2w1.typeform.com/to/mIpgxGDj">
                      {t("media.thursday.btn2")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="media-day">
                <h2>{t("media.thursday.day")}</h2>
                <a
                  href="https://1d4o4gdt2w1.typeform.com/to/mIpgxGDj"
                  target="_blank"
                  rel="noreferrer"
                  className="media-btn linkform-btn"
                >
                  {t("media.thursday.btn")}
                </a>
              </div>
            </div> */}

            {/* item 3*/}
            <div className="media-item">
              <h1>{t("media.blog.title")}</h1>
              <div className="media-desc blog-desc">{t("media.blog.text")}</div>
              <div className="media-categories-row media-blog-desc">
                {Object.entries(
                  t("media.blog.items", { returnObjects: true })
                ).map((el, id) => (
                  <div key={id} className="media-categories-item">
                    <h3 className="media-blog-h3">{el[1].title}</h3>
                    <span>{el[1].text}</span>
                  </div>
                ))}
              </div>
              <div className="media-text blog-desc">
                {t("media.blog.explore")}
              </div>
              <div className="media-blog-items">{posts}</div>
              <Link to="/blogs">
                <div style={{ margin: "0 auto" }} className="linkform-btn">
                  {t("media.blog.btn")}
                </div>
              </Link>
            </div>
          </div>

          <div className="engage">
            <h1>{t("media.engage_title")}</h1>
            <div className="media-desc">{t("media.engage_desc")}</div>
          </div>
        </div>
      </div>
      {/* <div className="customers">
        <div className="customers-bg" />
        <div className="customers-container">
          <h1>{t("our_work.customers")}</h1>
          <div className="customers-items">
            {Object.entries(
              t("our_work.customers_items", { returnObjects: true })
            ).map((el, id) => (
              <div key={id} className="customers-item">
                <h2>{el[1][0]}</h2>
                <span>{el[1][1]}</span>
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Media;
