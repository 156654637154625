const checkLinkInData = (data, slug, attributeKeys) => {
  const words = slug.split("-").map((word) => word.toLowerCase());

  const filteredArray = data.filter((obj) => {
    const attributeValues = attributeKeys
      .map((key) => obj.attributes[key])
      .filter((value) => typeof value === "string")
      .flatMap((value) => value.toLowerCase().split(/[\s-]+/));

    return words.every((word) => attributeValues.includes(word));
  });

  return filteredArray;
};
export default checkLinkInData;
