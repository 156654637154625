import React from "react";
import { useTranslation } from "react-i18next";
import BlogList from "../BlogList/BlogList";
import "./OurBlog.scss";
import { Link } from "react-router-dom";

const OurBlog = ({ postData }) => {
  const { t } = useTranslation();

  const keywords = postData[0].yoast_head_json.schema["@graph"][0].keywords;

  return (
    <div className="ourblog">
      <div className="ourblog-container">
        <h1 className="ourblog-title">{t("blog.0")}</h1>

        {/* <div className="graphic-blog graphic-blog-bg">
          <div className="media-categories-row-2">
            <div className="media-categories-col">
              <div className="media-categories-item">
                <h3>
                  Graphic Design Fundamentals: A Comprehensive Guide for the
                  Young Graphic Designer
                </h3>
                <span>
                  Graphic design skills revolve around visual communication.
                  Irrespective of the design’s purpose, the goal is to create
                  something aesthetically pleasing that effectively conveys a
                  specific message. To accomplish this, graphic designers need
                  to consider the principles of graphic design.
                </span>
              </div>
              <Link to={postData[0].slug} className="linkform-btn">
                {t("media.blog.btn2")}
              </Link>
            </div>

            <div className="media-categories-col">
              <div className="single-blog-img">
                <img src={require("../../assets/images/frame.png")} alt="" />
                <div className="blog-tags">
                  {keywords?.slice(0, 2).map((keyword, id) => (
                    <div className="blog-tag" key={id}>
                      {keyword}
                    </div>
                  ))}
                </div>
                <div className="blog-author">{`By ${postData[0].yoast_head_json.author}`}</div>
              </div>
              <div className="episode-btn">
                <Link to="/blogs">{t("view_all.2")}</Link>
              </div>
            </div>
          </div>
        </div> */}

        {postData.length > 0 ? (
          <div>
            <BlogList postData={postData} />
          </div>
        ) : (
          <div style={{ color: "#111111" }} className="no-projects">
            {t("blog.1")}
          </div>
        )}
      </div>
    </div>
  );
};

export default OurBlog;
