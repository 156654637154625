import React from "react";
import formatDate from "../../utils/FormatDate";
import "./BlogList.scss";

const BlogItem = ({ postData }) => {
  const heroImg = postData?.excerpt.rendered;

  const match = heroImg.match(/https:\/\/[^<>\s]+/);
  const extractedLink = match ? match[0] : null;

  const title = postData.slug
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const formattedDate = formatDate(postData.date);
  const dateOnly = formattedDate.replace(/^(.*?)\s.*$/, "$1");
  const keywords = postData.yoast_head_json.schema["@graph"][0].keywords;

  return (
    <div className="blogs-card">
      {extractedLink ? (
        <img src={extractedLink} alt="" />
      ) : (
        <div className="blog-item-bg" />
      )}
      <h3>{title}</h3>
      <span>{dateOnly}</span>
      <div className="blog-tags">
        {keywords?.slice(0, 2).map((keyword, id) => (
          <div className="blog-tag" key={id}>
            {keyword}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogItem;
