import React from "react";
import Footer from "../components/Footer/Footer";
import LinkForm from "../components/LinkForm/LinkForm";
import Work from "../components/Work/Work";
import NavbarWrapper from "../components/Navbar/Navbar";

const HowWeWork = () => {
  return (
    <>
      <NavbarWrapper mode="light" burgerMode="light" />
      <Work />
      <LinkForm />
      <Footer />
    </>
  );
};

export default HowWeWork;
