// TestimonialRenderer.js
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css/navigation";

const TestimonialRenderer = ({ testimonials, isMobile, breakpoints }) => {
  return (
    <div className="testi-cards">
      {isMobile ? (
        Object.entries(testimonials).map((card, id) => (
          <SwiperSlide key={id}>
            {/* Render the content directly here */}
            <div className="testi-card">
              <div className="testi-desc">
                <div className="testi-logo">
                  <img
                    src={require(`../../assets/images/testimonials/testi-cards/${card[1].logo}`)}
                    alt="companies"
                  />
                </div>
                <h3 className="testi-card-title">{card[1].title}</h3>
                <div className="testi-card-text">
                  {card[1].text}
                  <span style={{ color: "transparent" }}>
                    {card[1].transparent}
                  </span>
                </div>
              </div>
              <div className="testi-person">
                <img
                  src={require(`../../assets/images/testimonials/testi-cards/${card[1].icon}`)}
                  alt="person"
                />
                <div className="testi-info">
                  <span className="testi-name">{card[1].name}</span>
                  <span className="testi-job">{card[1].job}</span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))
      ) : (
        <Swiper
          spaceBetween={30}
          loop={false}
          modules={[Navigation]}
          breakpoints={breakpoints}
          navigation
        >
          {Object.entries(testimonials).map((card, id) => (
            <SwiperSlide key={id}>
              {/* Render the content directly here */}
              <div className="testi-card">
                <div className="testi-desc">
                  <div className="testi-logo">
                    <img
                      src={require(`../../assets/images/testimonials/testi-cards/${card[1].logo}`)}
                      alt="companies"
                    />
                  </div>
                  <h3 className="testi-card-title">{card[1].title}</h3>
                  <div className="testi-card-text">
                    {card[1].text}
                    <span style={{ color: "transparent" }}>
                      {card[1].transparent}
                    </span>
                  </div>
                </div>
                <div className="testi-person">
                  <img
                    src={require(`../../assets/images/testimonials/testi-cards/${card[1].icon}`)}
                    alt="person"
                  />
                  <div className="testi-info">
                    <span className="testi-name">{card[1].name}</span>
                    <span className="testi-job">{card[1].job}</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default TestimonialRenderer;
