import React from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import LinkForm from "../components/LinkForm/LinkForm";
import Media from "../components/Media/Media";

const MediaPage = ({ postData }) => {
  return (
    <>
      <Navbar />
      <Media postData={postData} />
      <LinkForm />
      <Footer />
    </>
  );
};

export default MediaPage;
