import React from "react";
import { useTranslation } from "react-i18next";
import "./TeamMembers.scss";
import happiness from "../../assets/images/happiness.svg";

const TeamMembers = () => {
  const { t } = useTranslation();

  return (
    <div className="team-members">
      <div className="team-members-container">
        <div className="team-happiness">
          <img src={happiness} alt="" />
          <h1 className="team-members-title">
            {t("about_team_members_title.0")}
            <span>.</span>
          </h1>
        </div>
        <div className="team-members-categories">
          {Object.entries(t("about_team_members", { returnObjects: true })).map(
            (item, id) => {
              return (
                <div key={id} className="team-members-item">
                  <h2>{item[1].title}</h2>
                  <span>{item[1].description}</span>
                </div>
              );
            }
          )}
        </div>
        <div className="cities">
          {Object.entries(t("cities", { returnObjects: true })).map(([key]) => (
            <span key={key}>{t(`cities.${key}`)}</span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamMembers;
