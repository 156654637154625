import React from "react";
import "./Metrics.scss";
import { useTranslation } from "react-i18next";
import infinite from "../../assets/images/infinite.svg";

const Metrics = () => {
  const { t } = useTranslation();

  return (
    <div className="metric">
      <div className="metric-container">
        <h1 className="metric-title">{t("metric_title.0")}</h1>
        <div className="metrics">
          {Object.entries(t("metrics", { returnObjects: true })).map(
            (item, id) => {
              if (id === 1)
                return (
                  <div key={id} className="metrics-item">
                    <img src={infinite} alt="" />
                    <span>{item[1].description}</span>
                    <span>{item[1].note}</span>
                  </div>
                );
              return (
                <div key={id} className="metrics-item">
                  <h2>{item[1].title}</h2>
                  <span>{item[1].description}</span>
                  <span>{item[1].note}</span>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default Metrics;
