import React, { useState } from "react";
import { Link } from "react-router-dom";
import BlogItem from "./BlogItem";
import "./BlogList.scss";
import { useTranslation } from "react-i18next";

const BlogList = ({ postData = [] }) => {
  const [numToShow, setNumToShow] = useState(6);

  const { t } = useTranslation();

  const sortedPosts = [...postData].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const posts = sortedPosts.slice(0, numToShow).map((post, id) => {
    return (
      <Link to={`/blogs/${post.slug}`} key={id}>
        <BlogItem postData={post} />
      </Link>
    );
  });

  const handlePostsViewMoreClick = () => {
    setNumToShow(sortedPosts.length);
  };

  return (
    <div className="blog-list">
      <div className="blog-list-container">{posts}</div>
      {numToShow < sortedPosts.length && (
        <div className="projects-button" onClick={handlePostsViewMoreClick}>
          {t("viewMore.0")}
        </div>
      )}
    </div>
  );
};

export default BlogList;
