export const TESTIMONIALS_COMPANIES = [
  "sony.svg",
  "tiktok.svg",
  "ubisoft.svg",
  "teos.svg",
  "pico-white.svg",
  "son-video.svg",
  "voila.svg",
];

export const FOOTER_LINKS = [
  [
    "/how-we-work",
    "/about",
    "/projects",
    "/contact#faq",
    "/pricing",
    "/media"
  ],
  [
    "/projects?filter=branding",
    "/projects?filter=graphic%20design",
    "/projects?filter=marketing%20assets",
    "/projects?filter=presentations",
    "/projects?filter=photos",
    "/projects?filter=social%20media",
    "/projects?filter=videos",
    "/projects?filter=web",
  ],
  ["/contact", "/terms-conditions", "/privacy-policy"],
];

export const videoData = [
  {
    id: "1",
    title: "202209_TEOS Introduction video",
    url: "/video-filter/202209_TEOS Introduction video.mp4",
  },
  {
    id: "2",
    title: "Pepsico Sizzle 2024 - V8",
    url: "/video-filter/Pepsico Sizzle 2024 - V8.mp4",
  },
  {
    id: "3",
    title: "PICO 4 Enterprise Product Video_03",
    url: "/video-filter/PICO 4 Enterprise Product Video_03.mp4",
  },
  {
    id: "4",
    title: "PICO_30sec 9x16 AD 1 EN EU",
    url: "/video-filter/PICO_30sec 9x16 AD 1 EN EU.mp4",
  },
  {
    id: "5",
    title: "PICO_Business Suite",
    url: "/video-filter/PICO_Business Suite.mp4",
  },
  {
    id: "6",
    title: "RoadToParis Basket Video - 1920x1080",
    url: "/video-filter/RoadToParis Basket Video - 1920x1080.mp4",
  },
  {
    id: "7",
    title: "Saudia Airline - V7",
    url: "/video-filter/Saudia Airline - V7.mp4",
  },
  {
    id: "8",
    title: "TEOS BRAVIA Supervisor Tool - V2",
    url: "/video-filter/TEOS BRAVIA Supervisor Tool - V2.mp4",
  },
  {
    id: "9",
    title: "TikTok France_ApprendreSurTikTok_V3",
    url: "/video-filter/TikTok France_ApprendreSurTikTok_V3.mp4",
  },
  {
    id: "10",
    title: "TikTok France_FashionCompetition_V3",
    url: "/video-filter/TikTok France_FashionCompetition_V3.mp4",
  },
  {
    id: "11",
    title: "TikTok France_FestivalDuLivre_V4",
    url: "/video-filter/TikTok France_FestivalDuLivre_V4.mp4",
  },
  {
    id: "12",
    title: "global-tiktok",
    url: "/video-filter/global-tiktok.mp4",
  },
  {
    id: "13",
    title: "tt4b",
    url: "/video-filter/tt4b.mp4",
  },
  {
    id: "14",
    title: "crea",
    url: "/video-filter/crea.mp4",
  },
  {
    id: "15",
    title: "soc-media",
    url: "/video-filter/soc-media.mp4",
  },
  {
    id: "16",
    title: "soc-media-sony",
    url: "/video-filter/soc-media-sony.mp4",
  },
];
