import React from "react";
import "./AgencyDeck.scss";
import Arrow from "../../assets/images/hover-arrow-black.svg";
import { useTranslation } from "react-i18next";

const AgencyDeck = () => {
  const { t } = useTranslation();

  const downloadPDF = async () => {
    const response = await fetch("/IntroductionDeck.pdf");
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Sarani Introduction Deck.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="agency">
      <h1>{t("agency.0")}</h1>
      <h4>{t("agency.1")}</h4>
      <div>
        <span>{t("agency.2")}</span>
        <img onClick={downloadPDF} src={Arrow} alt="" />
      </div>
    </div>
  );
};

export default AgencyDeck;
