import React from "react";
import ProjectsList from "../ProjectsList/ProjectsList";
import "./OurProject.scss";
import { useTranslation } from "react-i18next";

const OurProject = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="ourproject">
      <div className="ourproject-container">
        <h1 className="ourproject-title">{t("project_title.0")}</h1>
        <div className="">
          <ProjectsList data={data} />
        </div>
      </div>
    </div>
  );
};

export default OurProject;
