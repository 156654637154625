import React from "react";
import Projects from "../Projects/Projects";
import "./Ready.scss";
import { useTranslation } from "react-i18next";

const Ready = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="ready">
      <h1>{t("ready.0")}</h1>
      <div className="mbt">
        <a
          href="https://1d4o4gdt2w1.typeform.com/to/mIpgxGDj"
          target="_blank"
          rel="noreferrer"
        >
          <div className="wh-btn">{t("button.2")}</div>
        </a>
      </div>
      <Projects data={data} borderClass="border_radius" />
    </div>
  );
};

export default Ready;
