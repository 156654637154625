import React from "react";
import { useTranslation } from "react-i18next";
import "./Pricing.scss";

const Pricing = ({ doc }) => {
  const { t } = useTranslation();

  return (
    <div className="pricing">
      <div className="pricing-container">
        <div>
          <h1>{t("pricing.title")}</h1>
          <div className="pricing-text">{t("pricing.description")}</div>
        </div>

        <div className="pricing-targets">
          <h2>{t("pricing.subtitle")}</h2>
          <div className="pricing-targets-items">
            {Object.entries(t("pricing.items", { returnObjects: true })).map(
              (el, id) => (
                <div key={id} className="pricing-targets-item">
                  <div className="pricing-targets-item-img">
                    <img
                      src={require(`../../assets/images/pricing/${el[1].img}`)}
                      alt=""
                    />
                  </div>
                  <div>{el[1].text}</div>
                </div>
              )
            )}
          </div>
        </div>

        <div className="pricing-items">
          {Object.entries(
            t("pricing.pricing_items", { returnObjects: true })
          ).map((el, id) => (
            <div key={id} className="pricing-item">
              <a
                className="pricing-btn linkform-btn"
                href="https://1d4o4gdt2w1.typeform.com/to/mIpgxGDj"
                target="_blank"
                rel="noreferrer"
              >
                {t("button.3")}
              </a>
              <h3>{el[1].title}</h3>
              {el[1].items.map((item, idx) => (
                <div key={idx} className="pricing-item-content">
                  <span
                    className="pricing-item-content-title"
                    dangerouslySetInnerHTML={{ __html: item[0] }}
                  />
                  <span className="pricing-item-content-text">{item[1]}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="reasons">
        <div className="reasons-container">
          <h1>{t("pricing.reasons.title")}</h1>
          <div className="reasons-items">
            {Object.entries(
              t("pricing.reasons.items", { returnObjects: true })
            ).map((el, id) => (
              <div key={id} className="reasons-item">
                <h4>{el[1][0]}</h4>
                <div>{el[1][1]}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
