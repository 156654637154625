import React from "react";
import { useTranslation } from "react-i18next";
import "./Expertise.scss";
import ExpertiseItem from "./ExpertiseItem";
import { Link } from "react-router-dom";

const Expertise = () => {
  const { t } = useTranslation();

  return (
    <div className="expertise">
      <div className="expertise-container">
        <h1 className="expertise-title">
          {t("expertise_title.0")}
          <span>.</span>
        </h1>
        <div className="expertise-categories">
          {Object.entries(t("expertise_items", { returnObjects: true })).map(
            (item, id) => {
              const itemTitle = Object.keys(item[1]);
              const itemSubtitles = Object.values(item[1]);
              return (
                <ExpertiseItem
                  key={id}
                  itemTitle={itemTitle}
                  itemSubtitles={itemSubtitles}
                  id={id}
                />
              );
            }
          )}
        </div>
        <Link to="/projects">
          <div className="ylw-btn">{t("button.1")}</div>
        </Link>
      </div>
    </div>
  );
};

export default Expertise;
