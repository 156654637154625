import React from "react";
import { useTranslation } from "react-i18next";
import "./Team.scss";

const Team = () => {
  const { t } = useTranslation();

  return (
    <div className="team">
      <div className="team-container">
        <h1>{t("about_team_title.0")}</h1>
        <div className="team-cards">
          {Object.entries(t("about_team", { returnObjects: true })).map(
            (el, id) => (
              <div key={id} className="team-member">
                <img
                  src={require(`../../assets/images/team/${el[1].img}`)}
                  alt=""
                />
                <div>{el[1].name}</div>
                <span>{el[1].job}</span>
              </div>
            )
          )}
        </div>
        <div className="team-note">{t("about_quote_values.4")}</div>
        <h4>{t("about_quote_values.0")}</h4>
      </div>
    </div>
  );
};

export default Team;
