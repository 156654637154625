import React from "react";
import { useTranslation } from "react-i18next";
import "./AddedValue.scss";
import value1 from "../../assets/images/values/1.svg";
import value2 from "../../assets/images/values/2.svg";
import value3 from "../../assets/images/values/3.svg";
import value4 from "../../assets/images/values/4.svg";

const AddedValue = () => {
  const { t } = useTranslation();
  const values = [value1, value2, value3, value4];

  return (
    <div className="added-value">
      <div className="added-value-container">
        <h1 className="added-value-title">
          {t("added_value_titles.0")}
          <span>.</span>
        </h1>
        <h3>{t("added_value_titles.1")}</h3>
        <div className="values-categories">
          {values.map((item, id) => {
            return (
              <div key={id} className="values-item">
                <img src={item} alt="" />
                <span>{t(`added_values.${id}`)}</span>
              </div>
            );
          })}
        </div>
        <h1 className="added-value-title">{t("added_value_titles.2")}</h1>
      </div>
    </div>
  );
};

export default AddedValue;
