import React from "react";
import "./Footer.scss";
import logo from "../../assets/images/logo.png";
import sarani from "../../assets/images/sarani.svg";
import ig from "../../assets/images/ig.svg";
import link from "../../assets/images/in.svg";
import email from "../../assets/images/email.svg";
import { FOOTER_LINKS } from "../../constants/arrays";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Footer = () => {
  const { t } = useTranslation();

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const sectionItems = Object.entries(
    t("footer_sections", { returnObjects: true })
  ).map((section, id) => (
    <div key={id} className="footer-section">
      <h4>{t(`footer_titles.${id}`)}</h4>
      {section[1].map((category, idx) => {
        return (
          <Link key={idx} to={FOOTER_LINKS[id][idx]}>
            {category}
          </Link>
        );
      })}
    </div>
  ));

  const copyright =
    i18next.language === "ar"
      ? t("copyright.0")
      : `${t("copyright.0")} ${getCurrentYear()} ${t("copyright.1")}`;

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <div className="footer-title">
            <img className="footer-logo" src={logo} alt="logo" />
            <img className="footer-sarani" src={sarani} alt="sarani" />
          </div>
          <p className="footer-copyright">
            {copyright} <br />
            {t("rights_reserved.0")}
          </p>
          <div className="footer-social">
            <a href="https://www.instagram.com/sarani.studio">
              <img src={ig} alt="instagram" />
            </a>
            <a href="https://www.linkedin.com/company/sarani-studio?originalSubdomain=fr">
              <img src={link} alt="linkedin" />
            </a>
            <a href="mailto:team@sarani.studio">
              <img src={email} alt="email"></img>
            </a>
          </div>
        </div>
        {sectionItems}
      </div>
    </div>
  );
};

export default Footer;
