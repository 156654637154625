import React from "react";
import { Link } from "react-router-dom";
import GraphicSvg from "../../assets/images/graphic_design.svg";
import MarketingSvg from "../../assets/images/marketing_assets.svg";
import Web from "../../assets/images/web.svg";
import Social from "../../assets/images/social-videos.svg";
import "./Purpose.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const LINKS = [
  "branding",
  "graphic%20design",
  "marketing%20assets",
  "presentations",
  "photos",
  "socials",
  "videos",
  "web",
];

const Purpose = () => {
  const { t } = useTranslation();

  const svgImages = {
    en: [
      GraphicSvg,
      GraphicSvg,
      MarketingSvg,
      MarketingSvg,
      Social,
      MarketingSvg,
      Social,
      Web,
    ],
    fr: [
      GraphicSvg,
      GraphicSvg,
      MarketingSvg,
      MarketingSvg,
      Social,
      MarketingSvg,
      Social,
      Web,
    ],
    ar: [null, null, null, null, null, null, null, null],
  };

  const circles = svgImages[i18next.language] || svgImages.en;
  return (
    <div className="purpose">
      <div className="purpose-container">
        <h1 className="purpose-title">{t("purpose_title.0")}</h1>
        <div className="purposes">
          {Object.entries(t("purposes", { returnObjects: true })).map(
            ([idx]) => {
              const svgIndex = Math.floor(idx / 2);
              const svgImage = circles[svgIndex];

              if (idx % 2 !== 0)
                return (
                  <span
                    style={{ color: "#faca15" }}
                    key={idx}
                    className="purposes-item"
                  >
                    {t(`purposes.${idx}`)}
                  </span>
                );
              return (
                <Link key={idx} to={`/projects?filter=${LINKS[idx / 2]}`}>
                  <span key={idx} className="purposes-item">
                    {t(`purposes.${idx}`)}
                    {i18next.language !== "ar" && (
                      <img src={svgImage} alt="purposes" />
                    )}
                  </span>
                </Link>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default Purpose;
